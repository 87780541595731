export const state2url = s => {
    let url = s && 'tags' in s ? "/"+Object.keys(s.tags)
        .filter(d=>s.tags[d])
        .map(d=>d+"/"+s.tags[d])
        .join('/') : "/";
    if(s && 'options' in s && Object.keys(s.options).length) {
        let opt = Object.keys(s.options)
            .filter(d=>typeof s.options[d] !== 'function' && s.options[d] && s.options[d] != undefined)
            .map(d=>d+"="+(s.options[d] instanceof Date ? +s.options[d] : s.options[d]))
            .join('&');
        if(opt !== '') url+=(url.match(/\/$/) ? "?" : "/?")+opt;
    }
    return url;
};

export const url2state = (url) => {
    console.log("url2state",url);
    const s = {
        tags: url
            .split(/\//)
            .filter(a=>a.length)
            .map(d=>d.replace(/\?.*$/,''))
            .reduce((s,v,i,a)=>{if(i<a.length-1 && i % 2 == 0) s[v]=a[i+1]; return s;},{})
    };
    s.options = url.substr(url.indexOf('?')+1)
        .split(/&/)
        .filter(d=>d.match(/.+=.*/))
        .reduce((i,d)=>{
            var k = d.split(/=/)[0];
            var v = d.split(/=/)[1];
            i[k] = v;
            return i;
        },{});
    if(Object.keys(s.options).length == 0) delete s.options;
    return s;
};

const donotify = (n,s) =>n.map(f=>f(s));

export const changestate = (state,notify,change) => {

    let numc = 0;
    const cs = (s,c) => {
        Object.keys(c).map(cc=>{
            if(cc in s && typeof s[cc] === 'object' && s[cc] !== null && !('then' in s[cc])) cs(s[cc],c[cc]);
            else {
                console.log('setting',cc,JSON.stringify(c[cc]).substr(0,50));
                if(c[cc] === '') delete s[cc];
                else s[cc] = c[cc];
                numc++;
            }
        });
        return s;
    };
    const oldurl = state2url(state);
    if(typeof change === 'function') numc = change(state);
    else cs(state,change);
    const url = state2url(state);
    if(numc > 0) {
        console.log(numc,"changes to state",oldurl,'->',url);
        donotify(notify,state);
    }
    if(oldurl != url && typeof history != 'undefined') history.pushState({}, "", url);

    return url;
};

const state = (s) => {
    const _s={...s};
    const _n=[]; 
    if(typeof history != 'undefined') 
        window.onpopstate = history.onpushstate = () => { 
            console.log('history triggered');
            const us = url2state(location.href.replace(location.origin,''));
            changestate(_s,_n,{
                tags: {...Object.keys(_s.tags).reduce((s,d)=>({...s,[d]:''}),{}), ...us.tags},
                options: {...Object.keys(_s.options).reduce((s,d)=>({...s,[d]:''}),{}), ...us.options},
            });
        };
    return({
        url: ()=>state2url(_s),
        change: c=>changestate(_s,_n,c),
        notify: n=>_n.push(n),
        get: ()=>_s
    });
};

export default state;
