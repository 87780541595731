import React, { Component, memo as Memo } from 'react';

import {
    View, 
    Text, 
    TextInput,
    Button,
    Svg,
    Circle,
    Line,
    G,
    Path,
    Polygon,
    SvgText,
    List,
    ListItem,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHeader,
    TableHead,
    Form,
    Hidden,
    A,
    VP,
    REM,
    H1,
    Fragment,
    Animation,
    Storage,
    Loading,
    historyhandler,
    orientation,
    screensize
} from './Platform';

import { state2url, url2state } from "./State";
import { getCar } from "./Data";
import { setStyleSheet } from "./Styler";

const _p = p =>({path: p.path});

const Error = Memo(props => (
    <Text style={{fontSize: REM(1.5), padding: REM(0.5), textAlign: 'center', backgroundColor: '#faa'}}>{props.error.message}</Text>
));

const Info = Memo(props => (
    <Svg {..._p(props)} style={props.style} viewBox="0 0 24 24"><Path fill="none" d="M0 0h24v24H0V0z"/><Path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></Svg>
));

const Cross = Memo(props => (
    <Svg {..._p(props)} style={{marginRight: REM(1), marginLeft: REM(1),width: REM(2), height: REM(2), flex: 1}} viewBox="0 0 24 24"><Path fill={props.fill} d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></Svg>
));

const ShowMore = Memo(props => (
    <Svg {..._p(props)} style={{width: REM(1), height: REM(1), flex: 1}} viewBox="0 0 408 408">
        <Path fill={props.fill} d="M204,102c28.05,0,51-22.95,51-51S232.05,0,204,0s-51,22.95-51,51S175.95,102,204,102z M204,153c-28.05,0-51,22.95-51,51 s22.95,51,51,51s51-22.95,51-51S232.05,153,204,153z M204,306c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51 S232.05,306,204,306z" />
    </Svg>
));

const GraphIcon = Memo(props => (
    <Svg {..._p(props)} style={{marginRight: REM(1), marginLeft: REM(1), width: REM(2), height: REM(2), flex: 1}} viewBox="0 0 24 24"><Path fill={props.fill} d="M10 20h4V4h-4v16zm-6 0h4v-8H4v8zM16 9v11h4V9h-4z"/></Svg>
));

const Plus = Memo(props => (
    <Svg {..._p(props)} style={{marginRight: REM(1), marginLeft: REM(1), width: REM(2), height: REM(2), flex: 1}} viewBox="0 0 24 24"><Path fill={props.fill} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></Svg>
));

const SearchIcon = Memo(props => (
    <Svg {..._p(props)} style={{flex: 1, width: REM(1), height: REM(1), marginRight: REM(1), marginLeft: REM(0.5)}} viewBox="0 0 446.25 446.25"> 
        <Path fill={props.fill} d="M318.75,280.5h-20.4l-7.649-7.65c25.5-28.05,40.8-66.3,40.8-107.1C331.5,73.95,257.55,0,165.75,0S0,73.95,0,165.75S73.95,331.5,165.75,331.5c40.8,0,79.05-15.3,107.1-40.8l7.65,7.649v20.4L408,446.25L446.25,408L318.75,280.5z M165.75,280.5C102,280.5,51,229.5,51,165.75S102,51,165.75,51S280.5,102,280.5,165.75S229.5,280.5,165.75,280.5z" />
    </Svg>
));

const LeftArrow = Memo(props => (
    <Svg {..._p(props)} style={{flex: 1, width: REM(1), height: REM(1), margin: REM(1)}} viewBox="0 0 408 408">
        <Path fill={props.fill} d="M408,178.5H96.9L239.7,35.7L204,0L0,204l204,204l35.7-35.7L96.9,229.5H408V178.5z"/>
    </Svg>
));

const ListIcon = Memo(props => (
    <Svg style={{flex:1,width:REM(2),height:REM(2),margin:REM(1)}} viewBox="0 0 24 24">
        <Path fill={props.fill} d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"/>
    </Svg>
));

const RightArrow = Memo(props => (
    <Svg {..._p(props)} style={{...props.style,width: REM(1), height: REM(1)}} viewBox="0 0 306 306">
        <Polygon fill={props.fill} points='94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153' />
    </Svg>
));

const Triangle = Memo(() => (
    <Svg style={{marginRight: REM(1), width: REM(1), height: REM(1)}} viewBox="0 0 400 400">
        <Path 
            fill='none'
            stroke='#000'
            strokeWidth='20'
            d="m 200,75 150,250 -300,0 z"
        />
    </Svg>
));

const InfoBox = Memo(props => (
    <View {..._p(props)} path={props.path} style={{ paddingLeft: REM(1), paddingRight: REM(2),  flex: 2, display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
        <View style={{marginBottom: REM(2)}}>
            <Text style={{textAlign: 'right', marginRight: REM(3)}}>Registration Number</Text>
            <H1>Check this Vehicle</H1>
            <Text>An app to help you judge the quality of used vehicles</Text>
        </View>
        <View style={{display: 'flex', flexDirection: 'row'}}><Triangle /><Text>Look up MOT histories of vehicles</Text></View>
        <View style={{display: 'flex', flexDirection: 'row'}}><Triangle /><Text>Rate the quality of vehicles based on their MOT history</Text></View>
        <View style={{display: 'flex', flexDirection: 'row'}}><Triangle /><Text>Compare ratings of several vehicles</Text></View>
        <View style={{display: 'flex', flexDirection: 'row'}}><Triangle /><Text>Compare a vehicle to others of the same model and mileage</Text></View>
    </View>
));

const HowBox = Memo(props => (
    <View {..._p(props)} style={{flex: 1}}>
        <Text>How does it work?</Text>

        <Text>The <A changeState={props.changeState} href="https://dvsadigital.blog.gov.uk/2018/01/05/opening-up-our-mot-history-data/">DVSA MOT history data API</A> provides details of every MOT test on every car in the UK since 2006. Using <A changeState={props.changeState} href="https://en.wikipedia.org/wiki/Machine_learning">machine learning</A>, the app rates vehicles based on their own and all other vehicle's history to predict how likely it is to pass its next MOT.</Text>
        
        <Text>This allows the app to:</Text>

        <List>
            <ListItem><Text>Look up MOT histories of vehicles</Text></ListItem>
            <ListItem><Text>Rate the quality of vehicles based on their MOT history</Text></ListItem>
            <ListItem><Text>Compare ratings of several vehicles</Text></ListItem>
            <ListItem><Text>Compare a vehicle to others of the same model and mileage</Text></ListItem>
        </List>

        <Text>All of this is presented simply by entering the registration number of the vehicle you are interested in.</Text>
    </View>
))

const SearchBox = Memo(props => (
    <Form {..._p(props)} changeState={props.changeState} style={{flex: 1, display: 'flex', flexDirection: 'row', padding: 0}}>
        <Hidden name='options.search' value='' />
        <Hidden name='options.details' value='' />
        <Hidden name='options.compare' value='' />
        <Hidden name='options.overall' value='' />
        <Hidden name='options.relative' value='' />
        <Hidden name='options.history' value='' />
        <View style={{flex: 1}} />
        <TextInput size={8}
            onChange={(t)=>t.replace(/[^a-zA-Z0-9]/,'').toUpperCase()}
            style={{
                flexBasis: 'auto',
                margin: REM(0.5),
                padding: 0,
                color: '#066',
                fontSize: REM(1.5),
                fontWeight: 'bold',
                height: REM(2.5),
                borderWidth: 0,
                backgroundColor: '#fff',
                borderColor: '#066'}}
            name='car' 
            append='true' />
        <Button style={{
            flexBasis: 'auto',
            border: 0,
            padding: 0,
            margin: 0,
            backgroundColor: '#066'}}>
            <Plus fill='#fff' />
        </Button>
    </Form>
));

const Grid = Memo(props => (
    <Svg {..._p(props)} style={{flex: 2}}>
        <G>
            <Line x1="0%" y1="0%" x2="0%" y2="100%" stroke='#eee' strokeWidth='1' />
            <Line x1="100%" y1="0%" x2="100%" y2="100%" stroke='#eee' strokeWidth='1' />
            <Line x1="0%" y1="100%" x2="100%" y2="100%" stroke='#eee' strokeWidth='1' />
            <Line x1="0%" y1="0%" x2="100%" y2="0%" stroke='#eee' strokeWidth='1' />
            <Line x1="50%" y1="0%" x2="50%" y2="100%" stroke='#eee' strokeWidth='1' />
            <Line x1="0%" y1="50%" x2="100%" y2="50%" stroke='#eee' strokeWidth='1' />
        </G>
        <G>
            <SvgText x="100%" y="50%" textAnchor='end' fill='#aaa'>Good vehicle</SvgText>
            <SvgText x="0%" y="50%" fill='#aaa'>Bad vehicle</SvgText>
            <SvgText x="50%" y="0%" dy="18" textAnchor='middle' fill='#aaa'>Good example</SvgText>
            <SvgText x="50%" y="100%" dy="-6" textAnchor='middle' fill='#aaa'>Bad example</SvgText>
        </G>
        <G>
            {props.cars ? props.cars.filter(d=>d.score>0.01&&d.mots.length>0).sort((a,b)=>b.score-a.score).map(c=>{
                let carscore = Math.round(100*(c.score-c.aavg.q1)/(c.aavg.q99-c.aavg.q1));
                let modelscore = [ [c.avg.q1,1], [c.avg.q25,25], [c.avg.q50,50], [c.avg.q99,99] ]
                    .reduce((s,d,i,a)=>c.score >= d[0] ? i < a.length-1 ? d[1]+(a[i+1][1]-d[1])*(c.score-d[0])/(a[i+1][0]-d[0]) : d[1] : s,0);
                carscore = carscore > 100 ? 100 : carscore;
                carscore = carscore < 0 ? 0 : carscore;
                modelscore = modelscore > 100 ? 100 : modelscore;
                modelscore = modelscore < 0 ? 0 : modelscore;
                let ascore = ["rgba(255,0,0,0.3)","rgba(255,255,0,0.3)","rgba(0,255,0,0.3)"][Math.ceil(3*(carscore+modelscore)/200)-1];
                const doclick=()=>props.changeState(s=>({...s, options: {...s.optionschange, details: c.reg}}));
                return(
                    <G key={"grid-"+c.reg}>
                        <Circle onPress={doclick} onClick={doclick} cx={carscore+"%"} cy={(100-modelscore)+"%"} r={16} fill={ascore} stroke='#aaa' />
                        <SvgText onPress={doclick} onClick={doclick} dy={modelscore < 50 ? "-8" : "10"} 
                            textAnchor={carscore < 50 ? "start" : "end"}
                            fill='#066'
                            x={carscore+"%"} 
                            y={(100-modelscore)+"%"}>{c.reg}</SvgText>
                    </G>
                );}) : null}
        </G>
    </Svg>
));

const Mot = Memo(mot => {
    const T = props => <Text {..._p(props)} style={{flex: 1, fontSize: REM(1), fontWeight: 'normal'}}>{props.children}</Text>;
    const H = props => <Text {..._p(props)} style={{flex: 1, fontSize: REM(1.5), fontWeight: 'bold'}}>{props.children}</Text>;
    return (
        <TableRow {..._p(mot)} style={{backgroundColor: mot.failed ? '#fee' : '#efe', padding: REM(0.5)}}>
            <TableCell style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <H>{(new Date(mot.date)).toLocaleDateString('en-GB',{dateStyle: 'short'})}, {mot.failed ? "FAILED" : "PASSED"}</H>
                <H>Mileage <T>{mot.mileage}</T></H>
                <H>Failures</H>
                {mot.fails.length ? mot.fails.map((f,i)=><T key={mot.testnumber+"-f"+i}>{String.fromCharCode(9651)} {f}</T>) : <T>None</T>}
                <H>Advisories</H>
                {mot.advisories.length ? mot.advisories.map((a,i)=><T key={mot.testnumber+"-a"+i}>{String.fromCharCode(9651)} {a}</T>) : <T>None</T>}
            </TableCell>
        </TableRow>
    );
});


const Gauge = props => (
    <View {..._p(props)} style={{width: VP(40), height: VP(50)}}>
        <Animation wait={0} time={1} transform={[{translateX: ['-300px',0] }]}>
            <Text>Chance of passing next MOT</Text>
        </Animation>
        <Svg viewBox="0 0 200 100" style={{width: VP(40), height: VP(20)}}>
            <Path d="M 100,5 l 0,7" transform="rotate(0,100,100)" fill='none' stroke='#aaa'></Path>
            <Path d="M 100,5 l 0,7" transform="rotate(45,100,100)" fill='none' stroke='#aaa'></Path>
            <Path d="M 100,5 l 0,7" transform="rotate(90,100,100)" fill='none' stroke='#aaa'></Path>
            <Path d="M 100,5 l 0,7" transform="rotate(270,100,100)" fill='none' stroke='#aaa'></Path>
            <Path d="M 100,5 l 0,7" transform="rotate(315,100,100)" fill='none' stroke='#aaa'></Path>
            <Path className="face" d="M5,100 a40,40 0 0,1 190,0" fill='none' stroke='#aaa'></Path>
            <SvgText x="100" y="30" textAnchor="middle">50%</SvgText>
            <SvgText x="190" y="100" textAnchor="end">100%</SvgText>
            <SvgText x="10" y="100" textAnchor="start">0%</SvgText>
            <Circle stroke='#aaa' fill='none' cx={100} cy={100} r="6" />
        </Svg>
        <Animation time={1} wait={1} style={{height: VP(2), width: VP(20), position: 'relative', top: VP(-2), left: 0}} transformOrigin={[VP(20),VP(1)]} transform={[{rotate: ['0deg',Math.round(180*props.score)+'deg']}]}>
            <Svg viewBox="0 0 20 2" width={VP(20)} height={VP(2)}>
                <Path stroke='#faa' d="M 2,1 L 20,1"></Path>
            </Svg>
        </Animation>
    </View>
);

const CompareCurve = Memo(props => {
    let o = Object.keys(props.curves).map(d=>parseInt(d)).sort((a,b)=>a-b);
    let closestbucket = +Object.keys(props.curves).reduce((s,d)=>Math.abs(d-props.score*100) < Math.abs(s-props.score*100) ? d : s,1000);
//    let c = Math.round(255*o.reduce((s,d,i,a)=>s=a[i]<=closestbucket?s+props.curves[d]:s,0)/o.reduce((s,d)=>s+=props.curves[d],0));
//    let tohex = n=>("0"+n.toString(16)).slice(-2);
    //<circle cx={Math.round(props.score*100)+"%"} cy={(90-0.7*props.curves[closestbucket])+"%"} r="0.4em" style={{fill: "#"+tohex(255-c)+tohex(c)+"00"}} />
            /*<SvgText 
                <SvgText x="50%" y="10%" textAnchor='middle'>Compared to other {props.cartype}</SvgText>
                <SvgText x="0" y="0" rotation="90" style={{transform: "rotate(90deg)"}}>Number of vehicles</SvgText>
                <SvgText x="100%" y="100%" dy="-10" textAnchor="end">Chance of passing MOT</SvgText>
            <Text>Graph showing how many {props.compname} have a particular pass chance with this vehicle{"'"}s pass chance highlighted </Text>
                dy={props.curves[closestbucket] > 50 ? "3" : "-3"}
                dx={props.score < 0.5 ? "0" : "-8"}
                x={Math.round(props.score*100)+"%"} 
                y={(90-0.7*props.curves[closestbucket])+"%"} 
                style={{textAnchor: props.score < 0.5 ? "start" : "end"}}>{props.reg}</SvgText>*/
    const rating = ["#faa","#ffa","#afa"][Math.ceil(3*props.compscore/100)-1];
            
    return (
        <Svg {..._p(props)} style={props.style}>
            <Line x1="50%" y1="0%" x2="50%" y2="100%" stroke='#eee' strokeWidth='5px' />
            <Line x1="0%" y1="90%" x2="100%" y2="90%" stroke='#eee' strokeWidth='5px' />
            <SvgText x="50%" y="10%" dx="-10" textAnchor='end'>Fail</SvgText>
            <SvgText x="50%" y="10%" dx="10" textAnchor='start'>Pass</SvgText>
            {o.map((p,i,a)=><Line stroke='#006' key={"curveline"+p} x1={p+"%"} y1={(90-0.7*props.curves[p])+"%"} x2={a[i+1]+"%"} y2={(90-0.7*props.curves[a[i+1]])+"%"} />).slice(0,-1)}
            <Circle fill={rating} cx={Math.round(props.score*100)+"%"} cy={(90-0.7*props.curves[closestbucket])+"%"} r="10" stroke="#006" />
        </Svg>
    );
});

const MotList = Memo(props => (
    <Table {..._p(props)} style={{flex: 1}}>
        <TableBody
            data={props.mots}
            keyExtractor={item=>item.testnumber}
            renderItem={c=><Mot {..._p(props)} {...c.item} />}
        ></TableBody>
    </Table>
));

const CarDetails = Memo(car => (
    <View {..._p(car)} style={{flex: 1, display: 'flex', flexDirection: 'column', padding: REM(0.5), flexWrap: 'wrap', overflow: 'hidden'}}>
        <Text style={{fontSize: REM(1.5)}}>{car.carname}</Text>
        <Text style={{fontSize: REM(1.5)}}>{car.year}, {Math.round(car.mileage/10000)*10}k miles</Text>
        <A changeState={car.changeState} changes={'options.details=,options.relative=,options.overall=,options.history='+car.reg}
            style={{borderBottomStyle: 'solid', borderBottomWidth: REM(0.2), borderBottomColor: '#eee', display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'space-between', padding: REM(0.5)}}>
            <Text style={{fontSize: REM(1.5), maxWidth: REM(8)}}>MOT pass chance</Text>
            <Text style={{flexShrink: 1, flexGrow: 1, fontSize: REM(3)}}>{Math.round(car.score*100)+"%"}</Text>
            <Info style={{flexShrink: 1, marginLeft: REM(1), width: REM(3), fill: '#066'}} fill='#066' />
        </A>

        {car.mots.length === 0 || car.score < 0.01 ? <Text style={{flex:1, maxWidth: '100%',marginTop: REM(1)}}>This vehicle has no MOT data available so we cannot make detailed predictions about it</Text> :
            <Fragment>
                <A changeState={car.changeState} changes={'options.details=,options.history=,options.relative=,options.overall='+car.reg} 
                    style={{flexGrow: 1, maxHeight: '50%', flexBasis: REM(3), borderBottomStyle: 'solid', borderBottomWidth: REM(0.2), borderBottomColor: '#eee', display: 'flex', flexDirection: 'row', alignItems: 'stretch', padding: REM(0.5), justifyContent: 'space-between'}}>
                    <Text style={{flex: 1, fontSize: REM(1.5), maxWidth: REM(8)}}>Overall Rating</Text>
                    <Rating style={{flex: 1, paddingLeft: REM(1), paddingRight: REM(1), fontSize: REM(1.5)}} score={Math.round(100*(car.score-car.aavg.q1)/(car.aavg.q99-car.aavg.q1))} />
                    <Info style={{flexShrink: 1, marginLeft: REM(1), width: REM(3), fill: '#066'}} fill='#066' />
                </A>
            
                <A changeState={car.changeState} changes={'options.details=,options.history=,options.overall=,options.relative='+car.reg} 
                    style={{flexGrow: 1, maxHeight: '50%', flexBasis: REM(3), justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'stretch', padding: REM(0.5)}}>
                    <Text style={{flex:1, fontSize: REM(1.5), maxWidth: REM(8)}}>Relative Rating</Text>
                    <Rating style={{flex: 1,paddingLeft: REM(1), paddingRight: REM(1),fontSize: REM(1.5)}} score={[[car.avg.q1,1],[car.avg.q25,25],[car.avg.q50,50],[car.avg.q99,99]].reduce((s,d,i,a)=>car.score >= d[0] ? i < a.length-1 ? d[1]+(a[i+1][1]-d[1])*(car.score-d[0])/(a[i+1][0]-d[0]) : d[1] : s,0)} />
                    <Info style={{flexShrink: 1, marginLeft: REM(1), width: REM(3), fill: '#066'}} fill='#066' />
                </A>
            </Fragment>}
    </View>));

const ComparePage = Memo(props => (
    <View {..._p(props)} style={{flex: 1, display: 'flex', flexDirection: 'column', padding: REM(0.5)}}>
        {orientation() == 'portrait' || screensize() == 'large' ? 
            <Fragment>
                <Text style={{fontSize: REM(1.5)}}>{props.car.carname}</Text>
                <Text style={{fontSize: REM(1.5), paddingBottom: REM(0.5), marginBottom: REM(0.5)}}>{props.car.year}, {Math.round(props.car.mileage/10000)*10}k miles</Text>

                <Text style={{fontSize: REM(1.5)}}>{props.compareheading}</Text>
                <View style={{flexBasis: REM(6), borderBottomStyle: 'solid', borderBottomWidth: REM(0.2), borderBottomColor: '#eee', display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'space-between', padding: REM(0.5)}}>
                    <Rating style={{flexGrow: 1, flexBasis: REM(8),fontSize: REM(1.5)}} score={props.score} />
                    <Text style={{flexShrink: 1, marginLeft: REM(0.5)}}>{props.details}</Text>
                </View></Fragment> : null}
        {typeof props.curves === 'object' && Object.values(props.curves).length > 3 ? <CompareCurve style={{flex: 2}} compscore={props.score} curves={props.curves} cartype={props.car.carname} reg={props.car.reg} score={props.car.score} compname='vehicles' /> : <Text>Not enough examples of same type and mileage to show detailed comparison</Text>}
    </View>
));

const Rating = Memo(props => {
    const rating = ["bad","average","good"][Math.ceil(3*props.score/100)-1];
    const ratingcolours = {'good': '#afa', 'average': '#ffa', 'bad': '#faa'}; 
    return (
        <View {..._p(props)} style={{...props.style, display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: ratingcolours[rating]}}>
            <Text style={{flex: 1, textAlign: 'center'}}>{props.pre}{rating}{props.post}</Text>
        </View>
    );
});

const InfoRow = Memo(props => {
    let carscore = Math.round(100*(props.score-props.aavg.q1)/(props.aavg.q99-props.aavg.q1));
    let modelscore = [ [props.avg.q1,1], [props.avg.q25,25], [props.avg.q50,50], [props.avg.q99,99] ]
        .reduce((s,d,i,a)=>props.score >= d[0] ? i < a.length-1 ? d[1]+(a[i+1][1]-d[1])*(props.score-d[0])/(a[i+1][0]-d[0]) : d[1] : s,0);
    let carrating = ["bad","average","good"][Math.ceil(3*carscore/100)-1];
    let modelrating = ["bad","average","good"][Math.ceil(3*modelscore/100)-1];
    let ratingcolours = {'good': '#afa', 'average': '#ffa', 'bad': '#faa'}; 
    if(props.mots.length === 0 || props.score < 0.01) {
        carrating = "?";
        modelrating = "?";
    }
    const LinkCell = p => <A changeState={props.changeState} {..._p(p)} style={{...p.style, display: 'flex', flexDirection: 'row'}} changes={p.changes ? p.changes : 'options.details='+props.reg}><TableCell style={{...p.style,display: 'flex', alignItems: 'center', flexDirection: 'row'}}>{p.children}</TableCell></A>;
    return (
        <TableRow {..._p(props)} style={{height: REM(3)}}>
            <LinkCell style={{flex: 2, flexBasis: REM(6), paddingLeft: REM(0.1)}}><Text style={{flex: 1}}>{props.reg}{props.last}</Text></LinkCell>
            <LinkCell style={{flex: 1, flexBasis: REM(3)}}><Text style={{flex: 1, textAlign: 'center'}}>{Math.round(props.score*100)}%</Text></LinkCell>
            <LinkCell style={{flex: 1, flexBasis: REM(4), backgroundColor: ratingcolours[carrating]}}><Text style={{flex: 1, textAlign: 'center'}}>{carrating}</Text></LinkCell>
            <LinkCell style={{flex: 1, flexBasis: REM(4), backgroundColor: ratingcolours[modelrating]}}><Text style={{flex: 1, textAlign: 'center'}}>{modelrating}</Text></LinkCell>
        </TableRow>
    );
});

const DetailsTable = Memo(props => (
    <Table {..._p(props)} style={{width: '100%', flex: 2,display: 'flex', flexDirection: 'column' }}>
        <TableHead>
            <TableRow style={{height: REM(1.5)}}>
                <TableHeader style={{flex: 1, flexBasis: REM(3)}}></TableHeader>
                <TableHeader style={{flex: 2, flexBasis: REM(6)}}><Text style={{textAlign: 'center'}}>Pass Chance</Text></TableHeader>
                <TableHeader style={{flex: 1, flexBasis: REM(4)}}><Text style={{textAlign: 'center'}}>Overall</Text></TableHeader>
                <TableHeader style={{flex: 1, flexBasis: REM(4)}}><Text style={{textAlign: 'center'}}>Relative</Text></TableHeader>
                <TableHeader style={{flex: 1, flexBasis: REM(2)}}></TableHeader>
            </TableRow>
        </TableHead>
        <TableBody
            data={Object.values(props.car).sort((a,b)=>b.score-a.score)}
            keyExtractor={item=>item.reg}
            renderItem={c=><InfoRow changeState={props.changeState} {..._p(props)} {...c.item} last={c.index==Object.values(props.car).length-1} menu={props.menu} />}
        />
    </Table>
));

export const TabNav = Memo(props => (
    <View {..._p(props)} topview="1" style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
        {React.Children.map(props.children,d=>props.active==d.key ? d : null)}
    </View>
));

export const NavHeader = Memo(props => (
    <View {..._p(props)} style={{display: 'flex', backgroundColor: '#066', flexDirection: 'row', alignItems: 'center', height: REM(3.5), justifyContent: 'space-between'}}>
        {props.backlink ? 
            <A confirmtext={props.backconfirm} changeState={props.changeState} changes={props.backlink}>
                {props.backicon ? props.backicon : <LeftArrow fill='#fff' />}
            </A> : null}
        {props.search ? 
            <SearchBox changeState={props.changeState} label="Search" /> :
            <H1 style={{flex: 1, textAlign: 'left', color: '#fff', margin: REM(0.5), maxHeight: REM(3.5)}}>{props.title}</H1>}
        {props.nosearch || props.search ? null : 
            <A changeState={props.changeState} changes='options.search=search'>
                <Plus fill='#fff' />
            </A>}
    </View>
));

export const MotApp = props => {

    let car = false;
    let active = 'Home';
    let details = '';
    let cars = false;
    let fetching = false;
    
    console.log("MotApp: render ",showState(props));
        
    if(props.tags.car) props.tags.car = props.tags.car.split(/,/)
        .filter((d,i,s)=>d && s.indexOf(d) === i)
        .map(d=>d.toUpperCase()).join(',');

    if(props.tags.car && !props.tags.car.split(/,/).reduce((s,d)=>s && d in {...props.data.car},true)) {
        props.changeState(s=>({...s,
            tags: {...s.tags, car: props.tags.car},
            data: {...s.data, ...{car: {...s.data.car, ...props.tags.car.split(/,/)
                .filter(c=>!(props.data && props.data.car && c in props.data.car))
                .reduce((s,c)=>({...s,[c]: getCar(c)
                    .then(d=>props.changeState(s=>({...s, data: {...s.data, car: {...s.data.car, ...{[c]: d}}}})))})
                ,{})
            }}}}));
    }

    if(props.data && props.data.car) {
        const badcars = props.tags.car ? props.tags.car.split(',').filter(d=>d in props.data.car && 'error' in props.data.car[d]) : [];
        if(badcars.length && props.tags.car.split(',').reduce((s,d)=>s||badcars.indexOf(d)>-1,false)) {
            props.tags.car = props.tags.car.split(',').filter(d=>badcars.indexOf(d)==-1).join(',');
            props.changeState(s=>({...s,
                error: {
                    message: 'Error fetching vehicle data: '+badcars.reduce((s,d)=>s.concat(props.data.car[d].error),[]).join(', '),
                    timeout: typeof window == 'undefined' ? null : setTimeout(()=>props.changeState(s=>({...s, 
                        error: null, data: {...s.data, 
                            car: Object.keys(s.data.car)
                                .filter(d=>badcars.indexOf(d)==-1)
                                .reduce((ss,d)=>({...ss, [d]: s.data.car[d]}),{}) 
                        }
                    })),10000),
                },
                tags: {...s.tags, car: props.tags.car}
            }));
        }
        car = props.tags.car.split(/,/)
            .reduce((s,c)=>(c in props.data.car 
                && !isPromise(props.data.car[c]) 
                && props.data.car[c].aavg
                && props.data.car[c].aavg.q1 ? {...s,[c]: props.data.car[c]} : s),{});
        fetching = props.tags.car.split(/,/)
            .reduce((s,c)=>c in props.data.car && isPromise(props.data.car[c]) ? true : s,false);
    }

    if(props.tags.car && car) {
        cars = Object.values(car).filter(d=>!(!d||'error' in d||isPromise(d)));
        if(props.options && props.options.details) {
            active='Rating';
            details = props.options.details;
        } else if(props.options && props.options.history) {
            active='History';
            details=props.options.history;
        } else if(props.options && props.options.overall) {
            active='Overall';
            details=props.options.overall;
        } else if(props.options && props.options.relative) {
            active='Relative';
            details=props.options.relative;
        } else if(cars.length > 1 && props.options && props.options.compare) 
            active='Compare';
        else {
            active='Rating';
            details = props.tags.car ? props.tags.car.match(/([^,]+)$/)[1] : null;
        }
    }
    
    return (<Fragment>
        {fetching ? <View style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'absolute', 
            width: '100%', 
            height: '100%', 
            zIndex: 1000
        }}>
            <View style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Loading />
            </View></View> : null}
        <TabNav path={['TabNav']} active={active}>
        
            <Fragment key='Home'>
                <NavHeader changeState={props.changeState} nosearch={true} search={true} />
                {props.error ? <Error error={props.error} /> : null}
                {props.about == "how" ? <HowBox changeState={props.changeState} /> : <InfoBox />}
            </Fragment>

            <Fragment key='Compare'>
                <NavHeader changeState={props.changeState} 
                    title='Compare'
                    backconfirm={props.options.search ? null : 'Are you sure you want to clear all vehicles?'}
                    backicon={props.options.search ? null : <Cross fill='#fff' />}
                    search={props.options.search}
                    backlink={props.options.search ? 'options.search=' : 'car='} />
                {props.error ? <Error error={props.error} /> : null}
                <Grid changeState={props.changeState} cars={cars} />
                {orientation() == 'portrait' || screensize() == 'large' ? <DetailsTable changeState={props.changeState} car={cars} menu={props.options && props.options.options ? props.options.options : ''} /> : null}
                {props.options && props.options.options ? 
                    <View topview='1' style={{marginTop: REM(3), position: 'absolute', borderStyle: 'solid', borderWidth: 1, borderColor: '#066', top: 0, left: '5%', width: '90%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
                        <A changeState={props.changeState} style={{flex: 1, padding: REM(1), borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: '#066'}} changes={'-car='+props.options.options+',options.options='}><Text style={{borderBottomColor: 'white'}}>Remove</Text></A>
                        <A changeState={props.changeState} style={{flex: 1, padding: REM(1), borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: '#066'}} changes={'options.details='+props.options.options}><Text>Show Details</Text></A>
                        <A changeState={props.changeState} style={{flex: 1, padding: REM(1)}} changes='options.options='><Text>Cancel</Text></A>
                    </View>
                    : null }
            </Fragment>

            <Fragment key='Rating'>
                <NavHeader changeState={props.changeState} backlink={cars.length > 1 ? 'options=,options.compare=1' : 'options=,car='} 
                    backicon={cars.length > 1 ? <GraphIcon fill='#fff' /> : null}
                    title={details.substr(0,10)} search={props.options.search} />
                {props.error ? <Error error={props.error} /> : null}
                {details && car[details] ? <CarDetails changeState={props.changeState} {...car[details]} /> : null}
            </Fragment>
            
            <Fragment key='Overall'>
                <NavHeader changeState={props.changeState} backlink={'options=,options.details='+details} 
                    title={details.substr(0,10)}
                    search={props.options.search} />
                {props.error ? <Error error={props.error} /> : null}
                {car[details] && <ComparePage score={Math.round(100*(car[details].score-car[details].aavg.q1)/(car[details].aavg.q99-car[details].aavg.q1))} car={car[details]} compareheading={'Compared to the all vehicles in the UK'} curves={car[details].acurves} avg={car[details].avg} details={'this vehicle\'s MOT pass chance compared to all vehicles'} />}
            </Fragment>
            
            <Fragment key='Relative'>
                <NavHeader changeState={props.changeState} backlink={'options=,options.details='+details} 
                    title={details.substr(0,10)}
                    search={props.options.search} />
                {props.error ? <Error error={props.error} /> : null}
                {car[details] && <ComparePage score={[[car[details].avg.q1,1],[car[details].avg.q25,25],[car[details].avg.q50,50], [car[details].avg.q99,99]].reduce((s,d,i,a)=>car[details].score>=d[0]?i< a.length-1?d[1]+(a[i+1][1]-d[1])*(car[details].score-d[0])/(a[i+1][0]-d[0]):d[1]:s,0)} car={car[details]} compareheading={'Compared to all '+Math.round(car[details].mileage/10000)*10+'k miles '+car[details].carname} curves={car[details].curves} avg={car[details].avg} details={'this vehicle\'s MOT pass chance compared to all vehicles of the same type and mileage'} />}
            </Fragment>
            
            <Fragment key='History'>
                <NavHeader changeState={props.changeState} backlink={'options=,options.details='+details}
                    title={details.substr(0,10)} search={props.options.search} />
                {props.error ? <Error error={props.error} /> : null}
                {car[details] && <Text style={{textAlign: 'center',fontSize: REM(1.5)}}>MOT History</Text>}
                {car[details] && car[details].mots && car[details].mots.length ? <View style={{flex: 1, overflow: 'scroll'}}>{car[details].score < 0.01 && <Text>There appears to be some problems with the MOT history for this car so we cannot make predictions about it</Text>}<MotList mots={car[details].mots} /></View>:<Text>There is no MOT history available for this vehicle so we cannot make predictions about it</Text>}
            </Fragment>
        </TabNav>
    </Fragment>
    );
};

const stylesheet = {
    '.top': {color: '#006', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch'},
    TableHeader: {backgroundColor: '#066', color: 'white', fontWeight: 'bold', padding: 1, textAlign: 'center'},
    TableHead: { padding: 0 },
    TableCell: { paddingTop: 5, paddingBottom: 5, alignItems: 'center', justifyContent: 'center', display: 'flex'},
    '.linkcell': { paddingTop: 5, paddingBottom: 5, alignItems: 'center', justifyContent: 'center', display: 'flex'},
    Button: {backgroundColor: '#066', color: 'white', fontWeight: 'bold', padding: 12, borderWidth: 0, marginLeft: 8},
    TextInput: {backgroundColor: 'white', color: '#066', borderColor: '#066', borderWidth: 2, fontWeight: 'bold', padding: 8},
    '.good': { background: 'rgba(0,255,0,0.3)' },
    '.average': { background: 'rgba(255,255,0,0.3)' },
    '.bad': { background: 'rgba(255,0,0,0.3)' },
    A: { textDecoration: 'none', color: '#066' },
    '.error': { color: 'red', flex: 0},
    '.active': { borderColor: '#066', backgroundColor: 'white', color: '#066'}
};

const isPromise = p=>p && typeof p == 'object' && 'then' in p;
const showState = props=>({...props, changeState: props.changeState ? 'ok' : 'emtpty', data: {...props.data
    ,car: Object.keys({...props.data.car})
        .reduce((s,d)=>({...s, [d]: isPromise(props.data.car[d]) ? 'Promise' : 'Data'}),{})}});
export class App extends Component {

    constructor(props) {
        super(props);
        this.state = {...props, 
            tags: {...props.tags}, 
            options: {...props.options}, 
            data: {...props.data},
            changeState: this.changeState.bind(this)
        };
        setStyleSheet(stylesheet);
        if(typeof history != 'undefined') 
            window.onpopstate = history.onpushstate = () => { 
                const us = url2state(location.href.replace(location.origin,''));
                this.changeState(s=>({...s, tags: {...us.tags}, options: {...us.options}}));
            };
        this.changes = [];
    }

    changeState(f,ret=false) {
        if(ret) return state2url(f({...this.state}));
        if('refresh' in this.state) {
            this.state = f(this.state);
            this.state.refresh(this.state);
        } else {
            this.changes.push(f);
            const runupdates = (() => {
                if(this.changes.length) { 
                    // wait if rendering
                    if(this.rendering) return setTimeout(runupdates,1);
                    this.changes.length && this.setState(s=>{
                        // roll up deferred changes
                        const ns = this.changes.reduce((s,f)=>f(s),s);
                        this.changes = [];
                        const newurl = state2url(ns);
                        if(newurl != this.lasturl) {
                            historyhandler(newurl,this.state.changeState);
                            this.lasturl = newurl;
                        }
                        return ns;
                    });
                }
            }).bind(this);
            setTimeout(runupdates,1);
        }
    }

    componentDidUpdate() {
        this.rendering = false;
        let oldurl = '';
        if(typeof location != 'undefined') oldurl = location.href.replace(location.origin,'');
        const url = state2url(this.state);
        Storage.setItem('state',JSON.stringify({tags: this.state.tags, options: this.state.options}));
        if(oldurl != url && typeof history != 'undefined') history.pushState({}, "", url);
    }

    componentDidMount() {
        this.rendering = false;
        if( (!('tags' in this.state) || !this.state.tags || Object.keys(this.state.tags).length === 0) &&
            (!('options' in this.state) || !this.state.options || Object.keys(this.state.options).length === 0)
        ) {
            Storage.getItem('state',d=>{
                try {
                    console.log('App: restoring saved state');
                    const s=JSON.parse(d);
                    this.changeState(()=>s);
                } catch(e) { console.log('App: '+e); }
            });
        }
        historyhandler('',this.state.changeState);
    }

    render() {
        this.rendering = true; 
        try {
            return <MotApp {...this.state} />;
        } catch(e) {
            return <Text>Error: {e.message}</Text>;
        }
    }
}

export default App;
