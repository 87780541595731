import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { setfetch } from './Data';
import { url2state } from './State';

const fetch = url => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve({response: xhr.response, json: () => JSON.parse(xhr.response)});
            } else {
                try {
                    const m = JSON.parse(xhr.response);
                    'error' in m && reject(m.error);
                } catch(e) {
                    reject(xhr.statusText);
                }
            }
        };
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send();
    });
};

setfetch(fetch);
const s = url2state(location.href.replace(location.origin,''));
const app = <App {...s} />;
ReactDOM.hydrate(app, document.getElementById('app'));
