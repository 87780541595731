import React from 'react';

const stylesheet = {};
//const noinherit = ['flex','display','flexDirection','alignItems','height','width','overflow','justifyContent','alignItems']
//    .reduce((s,d)=>{s[d]=1; return s;},{});
const inherit = ['azimuth','border-collapse','border-spacing','caption-side','color','cursor','direction','elevation','empty-cells','font-family','font-size','font-style','font-variant','font-weight','font','letter-spacing','line-height','list-style-image','list-style-position','list-style-type','list-style','orphans','pitch-range','pitch','quotes','richness','speak-header','speak-numeral','speak-punctuation','speak','speech-rate','stress','text-align','text-indent','text-transform','visibility','voice-family','volume','white-space','widows','word-spacing'].reduce((s,d)=>{s[d.replace(/-(\w)/g,(_,r1)=>r1.toUpperCase())]=1; return s;},{});
/*export const Styler = (name,comp,pprops={}) => {
    console.log('in styler for '+name);
    const f = (props) => {
        if(props === 'getname') { console.log('retrning name ',name); return name; }
        const path = props.path ? props.path : [];
        const foundstyle = { ...Object.assign(path.reduce((s,d,i)=>({ ...Object.assign(s,
            d in stylesheet ? 
                i == path.length-1 ? { ...Object.assign({ ...pprops.style },stylesheet[d]) } :
                    Object.keys(stylesheet[d]).reduce((s,d)=>d in noinherit ? s : Object.assign(s,stylesheet(d)))
                : {}
        )}),{}),{ ...props.style}) };
        path.push(name);
        console.log('running the wrapped component '+name);
        return comp(
            { ...Object.assign({ ...props},{style: foundstyle, path: path}) }
        );
    };
    return f;
};*/

export const setStyleSheet = s => Object.assign(stylesheet,s);
export const Children = p=>React.Children.map(p.children,d=>{
//    console.log(new Date().getTime(),p.path,p.state);
    if(d && d.type && d.type.name) {
//        console.log(d.type.name,p.path);
        const path = p.path ? p.path.slice() : [];
        path.push(d.type.name);
        //    return Styler(d.type && d.type.name != 'f' ? d.type.name : d.type('getname'),d.type,p);
//        console.log("    ".repeat(path.length)+d.type.name);
//        console.log(path.reduce((s,d)=>s+d.padEnd(10)+",",''));
        return React.cloneElement(d, {path: path, className: d.type.name, 
            style: {...(p.style?Object.keys(p.style).reduce((s,d)=>{ 
                if(d in inherit) s[d] = p.style[d]; return s; 
            },{}) : {}), ...d.props.style}
            });
    }
    return d;
});
    

export const SP = (p,e={},s=true) =>{
    let o = ({
        changeState: p.changeState,
        path: p.path ? p.path : [], 
        className: p.path ? p.path.slice(-1) : null,
        style: {
            ...(p.pstyle?p.pstyle:{}), // inherited styles first
            ...e, // then style specified in the component
            ...(p.style?p.style:{}) // then style specified in style on the instance of the component
        }
    });
    Object.keys(e).map(d=>{if(e[d]===null) delete o.style[d];});
    if(s === false) {
        delete o.path;
        delete o.changeState;
    }
    return o;
};
