let myFetch = typeof fetch === 'undefined' ? false : fetch;

export const setfetch = f=>myFetch=f;

export const getCar = c => {
    const host = typeof history === 'undefined' ? 'https://checkthisvehicle.com' : window.location.origin;
    function timeoutfetch(ms, promise) {
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                reject(new Error("timeout"));
            }, ms);
            promise.then(resolve, reject);
        });
    }
    return timeoutfetch(5000, myFetch(host+"/api/mot/?reg="+c))
        .then(d=>d.json())
        .catch(e=>({error: e ? e : 'network error for '+c}));
};
