import React from 'react';
import propTypes from 'prop-types';

import { SP, Children } from './Styler';

export const View = p => (
    <div {...SP(p,{},false)}>
        {Children(p)}
    </div>
);
var changestate = null;
var size = 'large';
var orient = 'landscape';
if(typeof window !== 'undefined') window.addEventListener("resize", ()=>{
    changestate(s=>s);
    orient = window.innerWidth < window.innerHeight ? 'portrait' : 'landscape';
    size = Math.min(window.innerWidth,window.innerHeight) < 400 ? 'small' : 'large';
});
export const historyhandler = (h,c) => changestate = c;
export const orientation = () => orient;
export const screensize = () => size;

export const Loading = () => <div style={{textAlign: 'center', flex: 1, padding: REM(1), maxHeight: REM(10), maxWidth: REM(10)}}>
    <svg style={{width:"5rem", height:"5rem"}} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#066">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"/>
                </path>
            </g>
        </g>
    </svg> 
</div>;

export const Storage = {
    getItem: (key,callback) => typeof window ==='undefined' ? callback(false) : callback(localStorage.getItem(key)),
    setItem: (key,value) => typeof window === 'undefined' ? false : localStorage.setItem(key,value)
};

export const Fragment = p => Children(p);
export const H1 = props => <h1 {...SP(props,{},false)}>{Children(props)}</h1>;
export const Text = props => (
    <div {...SP(props,{},false)}>
        {Children(props)}
    </div>
);

let animationstyle = {};
export const Animation = props => {
    return(
        <React.Fragment>
            {React.Children.map(props.children,c=>{
                if(props.transform) {
                    const aname = JSON.stringify(props.transform).replace(/[^0-9a-zA-Z]/g,'');
                    const stransforms = props.transform.map(t=>Object.keys(t).map(tn=>tn+'('+t[tn][0]+')').join(' ')).join(' ');
                    const etransforms = props.transform.map(t=>Object.keys(t).map(tn=>tn+'('+t[tn][1]+')').join(' ')).join(' ');
                    animationstyle[aname] = 
`@keyframes ${aname} { 
from { transform: ${stransforms}; } 
to { transform: ${etransforms}; } 
}`;
                    if(!c.props.style) c.props.style = {};
                    c.props.style = Object.assign(c.props.style,props.style);
                    c.props.style.animation = props.time+'s ease-out '+props.wait+'s 1 '+aname+' forwards running';
                   // c.props.style.transform = etransforms;
                    if(props.transformOrigin) c.props.style.transformOrigin = props.transformOrigin.join(' ');
                    return React.cloneElement(c,c.props);
                } else return c;
            })}
        </React.Fragment>
    );
};

export const AnimationStyle = () => <style>{Object.values(animationstyle).join("\n")}</style>;

export const TextInput = props => {
    const rid = +(new Date());
    if(typeof window !== 'undefined')
        setTimeout(()=>{const c=document.getElementById(rid); if(c) c.focus();},10);
    return(
        <React.Fragment >
            <input id={rid} size={props.size} {...SP(props,{},false)} 
                onChange={e=>e.target.value=props.onChange ? props.onChange(e.target.value) : e.target.value} 
                {...['name'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} />
        </React.Fragment>
    );
};

export const Button = props => {
    const f = ['name','value','style','changeState','pstyle'];
    const p = Object.keys(props)
        .filter(k=>!f.includes(k))
        .reduce((s,k)=>{s[k]=props[k];return s;},{});
    return (
        <React.Fragment>
            {'name' in props ? <input type='hidden' name={props.name} value={props.value} /> : null}
            <button {...SP(props,{},false)} type="submit" {...p}>{props.title ? props.title : props.children}</button>
        </React.Fragment>
    );
};

export const Svg = props => (
    <svg {...SP(props,{},false)} {...['width','height','viewBox','flex'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} >
        {Children(props)}
    </svg>
);

export const Circle = props => (
    <circle {...SP(props,{},false)} {...['onClick','transform','cx','cy','r','fill','stroke','strokeWidth'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} />
);

export const G = props => <g {...SP(props,{},false)}>{Children(props)}</g>;

export const Line = props => (
    <line {...SP(props,{},false)} {...['transform','x1','y1','x2','y2','stroke','strokeWidth','fill'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} />
);

export const Rect = props => (
    <rect {...SP(props,{},false)} {...['transform','x','y','width','height','stroke','strokeWidth','fill'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} />
);

export const Path = props => (
    <path {...SP(props,{},false)} {...['d','x','y','transform','stroke','strokeWidth','fill'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} />
);

export const Polygon = props => (
    <polygon {...SP(props,{},false)} {...['points','x','y','transform','stroke','strokeWidth','fill'].reduce((s,d)=>{s[d] = props[d]; return s;},{})} />
);

export const Tspan = props => (
    <tspan {...SP(props,{},false)} {...['viewbox','transform'].reduce((s,d)=>{s[d] = props[d]; return s;},{})}>
        {Children(props)}
    </tspan>
);

export const SvgText = props => (
    <text {...SP(props,{},false)} {...['onClick','dy','dx','x','y','textAnchor','transform','stroke','strokeWidth','fill'].reduce((s,d)=>{s[d] = props[d]; return s;},{})}>
        {Children(props)}
    </text>
);

export const List = props => (
    <ul {...SP(props,{},false)}>
        {Children(props)}
    </ul>
);

export const ListItem = props => (
    <li {...SP(props,{},false)}>{Children(props)}</li>
);

export const Form = props => {
    const append = {};
    let data = React.Children.toArray(props.children).filter(d=>'name' in d.props)
        .map(d=>{ 
            if(d.props.append) append[d.props.name] = true;
            return {name: d.props.name, value: '{'+d.props.name+'}'};
        });
    const doappend = (a,n) => a.split(',').filter(d=>d).concat(n).join(',');
    const mutate = (from,state) => from.reduce((s,d)=>(d.name.match(/^options\./) ?
        {...s, options: {...s.options, [d.name.replace(/^options\./,'')]: 
            d.name in append && d.name in s.options ? 
                doappend(s.options[d.name.replace(/^options\./,'')],d.value)
                : d.value}} 
        : {...s, tags: {...s.tags, [d.name]: 
            d.name in append && d.name in s.tags ? 
                doappend(s.tags[d.name],d.value)
                : d.value}})
    ,state);
    const action = e => {
        const elements = Array.from(e.target.elements)
            .map(d=>({name:d.name,value: d.value}))
            .filter(d=>d.name&&d.name!='u');
        props.changeState(s=>mutate(elements,s));
        e.preventDefault();
        e.target.reset();
        return false;
    };
    return(
        <form {...SP(props,{},false)} method='get' action='/r/' onSubmit={action} >
            <input type='hidden' name='u' value={props.changeState(s=>mutate(data,s),true)} />
            {Children(props)}
        </form>
    );
};
Form.propTypes = {
    changeState: propTypes.func
};

const width = typeof window === 'undefined' ? 400 : Math.min(window.innerWidth,window.innerHeight);
export const VP = vp => (vp*width/100).toFixed(2)+'px';
export const REM = rem => rem+'rem';


export const Hidden = props => <input type='hidden' name={props.name} value={props.value} />;
export const TableBody = props => <View {...SP(props,{flex:2, overflow: 'scroll'})}>{props.data.map((d,i)=>props.renderItem({item: d, index: i}))}</View>;
export const Table = props => <View {...SP(props)}>{props.children}</View>;
export const TableHead = props => <View {...SP(props)}>{props.children}</View>;
export const TableRow = props => <View {...SP(props,{display: 'flex', flex: 1, flexDirection: 'row'})}>{props.children}</View>;
export const TableHeader = props => <View {...SP(props,{})}>{props.children}</View>;
export const TableCell = props => <View {...SP(props,{})}>{props.children}</View>;
export const A = props => {
    if(props.href) return(<a {...SP(props,{textDecoration: 'none', color: 'inherit'},false)} className='hardlink'>{Children(props)}</a>);
    const dochanges = cs => props.changes ? ({...cs, ...props.changes.split(/,/).reduce((s,v)=>{
        let m = v.match(/^([+-])?(.*)=(.*)/);
        if(m) {
            let setv = v=>s.tags[m[2]] = v;
            if(m[2].match(/^options$/) && m[3] === '') setv = ()=>s.options={};
            if(m[2].match(/^options\./)) setv = v=>s.options[m[2].substr(8)]=v;
            if(m[1] == '+' && m[2] in s.tags)
                setv(s.tags[m[2]]
                    .split(',')
                    .filter(d=>d)
                    .concat(m[3])
                    .join(','));
            if(m[1] == '-' && m[2] in s.tags)
                setv(s.tags[m[2]]
                    .split(',')
                    .filter(d=>d&&d!=m[3])
                    .join(','));
            else setv(m[3]);
        }
        return s;
    },{tags: {...cs.tags}, options: {...cs.options}})}) : cs;
    return(<a {...SP(props,{textDecoration: 'none', color: 'inherit'},false)} 
        onClick={()=>(!props.confirmtext || confirm(props.confirmtext)) && props.changeState(dochanges)} 
        href={typeof window === 'undefined' ? props.changeState(dochanges,true) : null}>
        {Children(props)}</a>);
};
A.propTypes = {
    children: propTypes.func
};
Form.propTypes = {
    changeState: propTypes.func
};
